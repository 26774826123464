import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import QuoteContext from '../../context/quote-context';
import Thanks from '../../components/GetQuoteSteps/thanks';

export default function ThanksPage() {
  return(
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <Thanks reset={quote.reset} />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
